import React, { Component } from "react";
import { withGlobal } from "./GlobalProvider";
import "./stylesheets/Skills.css";

class Resume extends Component {
  render() {
    return (
      <>
        <div className="skills">
          <h3 className="skill-title">Skills</h3>
          <div>
            <div>
              <h4 className="skill-name">
                <u>
                  Technical{" "}
                </u>
                (Hard)
              </h4>
              <div className="skill-container">
                <div className="skill">
                  <h4>JavaScript</h4>
                </div>
                <div className="skill">
                  <h4>React</h4>
                </div>
                <div className="skill">
                  <h4>HTML</h4>
                </div>
                <div className="skill">
                  <h4>CSS</h4>
                </div>
                <div className="skill">
                  <h4>ES6</h4>
                </div>
                <div className="skill">
                  <h4>Node.js</h4>
                </div>
                <div className="skill">
                  <h4>Express</h4>
                </div>
                <div className="skill">
                  <h4>AJAX/HTTP</h4>
                </div>
                <div className="skill">
                  <h4>Mongoose</h4>
                </div>
                <div className="skill">
                  <h4>MongoDB</h4>
                </div>
                <div className="skill">
                  <h4>JSON</h4>
                </div>
                <div className="skill">
                  <h4>GIT</h4>
                </div>
              </div>
            </div>
            <div>
              <h4 className="skill-name">
                <u>
                  Interpersonal{" "}
                </u>
                (Soft)
              </h4>
              <div className="skill-container">
                <div className="skill">
                  <h4>Problem Solving</h4>
                </div>
                <div className="skill">
                  <h4>Customer Service</h4>
                </div>
                <div className="skill">
                  <h4>Management</h4>
                </div>
                <div className="skill">
                  <h4>Fast Learner</h4>
                </div>
                <div className="skill">
                  <h4>Time Management</h4>
                </div>
                <div className="skill">
                  <h4>Communication</h4>
                </div>
                <div className="skill">
                  <h4>Collaboration</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="bgimg-2">
                    <div className="caption"> </div>
                </div>
      </>
    );
  }
}

export default withGlobal(Resume);
