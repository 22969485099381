import React, {
    Component
} from 'react';
import {withGlobal} from './GlobalProvider'
import {Routes,Route} from 'react-router-dom'
import Navbar from './Navbar'
import MainContent from './MainContent'
import Resume from './Resume';
import './stylesheets/App.css'
import './stylesheets/Media.css'

class App extends Component {

    render() {
        return (
            <div>
                <Navbar/>
                <Routes>
                    <Route exact path='/' element={<MainContent/>} />
                    <Route path='/resume' element={<Resume/>} />
                </Routes>
            </div>
        )
    }

}

export default withGlobal (App)